.page-content{
    background-color: transparent;
    padding: 10px;
}
.page-content .row-principal{
    background-color: transparent;

}
.page-content .column-principal{
    background-color: transparent;
}

.page-content .row-principal.row-1{
    display: flex;
    justify-content: space-around;
    background-color: transparent;
}
.pages-sorteo{
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}
.row-principal.row-2{
    display: flex;
    gap: 10px !important;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    
    width: 100%;
}
.add-carton{
    background-color: #00000022;
    height: 150px;
    width: 130px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    justify-content: center;
    padding: 10px;
    row-gap: 20px;
    align-items: center;
    border:solid 2px green;
    font-size: 14px;
    font-weight: bold;
    
}
.add-carton input{
    height: 30px;
    background-color: white;
    border: solid 1px green;
    border-radius: 10px;
    padding-left: 10px;
    width: 110px;
}
.add-carton button{
    border-radius: 10px;
    height: 30px;
    background-color: black;
    color:white;
    width: 120px;
    border: none;
}
.add-carton button:hover{
    background-color: white;
    border: solid 1px gray;
    color:black;
    cursor: pointer;
    font-weight: bold;
}
.column-1.column-principal{
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.row-3.row-principal .tabla{
    display: flex;
    max-width: 100vw;
    overflow: hidden;
    overflow-x: auto;
    column-gap: 10px;
    height: 70px;
    align-items: center;
}
.row-3.row-principal .tabla .bolita{
    display: flex;
    min-width: 30px !important;
}
.div-tabla-detalles{
    display: flex;
    flex-direction: column;
}

.ganadores{
    width: 320px;
    border:solid 1px purple;
   
}
.ganadores .nro-sorteo{
    background-color: purple;
    padding: 10px;
    font-weight: 500;
    color: white;
}
.div-ganadores{
    padding: 10px;
}

.div-tabla-detalles.detalles{
    border:solid 2px purple;
    padding: 10px;
    justify-content: center;
    align-items: center;
}
.div-tabla-detalles li{
    font-size: 18px;
    font-weight: 500;
}

.accordion-header ,.accordion-button{
    background-color: purple !important;
    border:none  !important;
    color:white !important;
    padding: 0px !important;
}
.accordion-button{
    padding: 5px !important;
}
.accordion-item{
    background-color: transparent !important;
    color:#fff !important;
    border:none  !important;
    margin-bottom: 3px;

}

.accordion-header .tab-acordion{
    font-size : 11px;
    font-weight:900;
    display:flex;
    align-items:center;
    justify-content:space-between;
    width: 90%;
}

.accordion-header .collapsed::after{
    color:black !important;
    /*background-image: url("/public/images/bola_verde.png");*/
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 100%;
    font-weight: bold;
}
.accordion-body{
    background-color: transparent !important;
    padding: 0px !important;
    padding-top: 5px !important;
    gap: 5px;
    display: flex;
    flex-direction: column;
}

.accordion-titulo{
    font-size : 11px;
    display: flex;
    gap: 10px;
    font-weight: 600;
}
.accordion-titulo-carton{
   width: 20%;
}
.accordion-titulo-nombre{
    width: 60%;
}
.accordion-titulo-premio{
    width: 20%;
}

.accordion-contenido{
    font-size : 14px;
    display: flex;
    gap: 3px;
    align-items: center;
    justify-content: space-around;
}
.accordion-contenido-carton{
    font-size : 12px;
    width: 20%;
    background: #555;
    padding: 2px;
    border-radius: 5px;
    font-weight: 600;
}
.accordion-contenido-nombre{
    width: 60%;
    background: #555;
    padding: 2px;
    border-radius: 5px;
    font-weight: 600;
    text-overflow:ellipsis;
    white-space:nowrap; 
    overflow:hidden;

}
.accordion-contenido-premio{
    font-size : 12px;
    width: 20%;
    background: #555;
    padding: 2px;
    border-radius: 5px;
    color:greenyellow;
    font-weight: 700;
}


.pages-sorteo .react-tabs{
    width: 300px !important;
}

.panel-cartones{
    height: 50px;
    background: black;
    color: #fff;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.panel-cartones button{
    margin-left: 10px;
    background: purple;
    color: white;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;

}

.panel-cartones-sorteo b{
    color: rgb(248, 246, 248);
}
.panel-cartones-version b{
    color: rgb(248, 246, 248);
    margin-left: 10px;
}
.pages-cartones{
    overflow-x: hidden;
}
  

.item {
padding: 10px;
border-bottom: 1px solid #ccc;
}

.loading {
padding: 10px;
text-align: center;
color: #999;
}


@media only screen and (max-width:420px){
    .row-principal.row-2{
        grid-template-columns: repeat(2,210px);
        transform: scale(0.85);
        gap: 5px;
        justify-content: flex-start;
        column-gap: 5px;
        max-width: 100vw;
        overflow-x: hidden;
        align-items: center;
    }
    .add-carton{
        
        width: 100px;
      
    }
    .add-carton input{
        width: 100px;
    }
    .add-carton button{
        border-radius: 10px;
        height: 30px;
        background-color: black;
        color:white;
        width: 110px;
        border: none;
    }
    .comp-header{
        max-width: 80vw !important;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
    }
    .row-1.row-principal{
        flex-direction: column !important; 
    }
    .div-tabla-detalles.detalles{
        display: none !important;
    }
    .comp-youtube iframe{
        width: 100vw;
        height: 200px;

    }
    .comp-youtube{
        position: fixed;
        left: 0;
        right: 0;
        top: 50px;
    }
    .menu-link-header{
        display: none !important;
    }
    .comp-searchBar{
        display: none !important;
    }
    .comp-header .div-right-header{
        display: none !important;
    }
    .ganadores{
        width: 100vw;
        border:solid 1px purple;
       
    }
    .pages-sorteo .page-content{
        padding: 0 !important;
        margin-top: 300px;
    }
    .page-content .comp-tablero{
        position: fixed;
        left: 0;
        right: 0;
        top: 250px;
        background: white;
        border: solid 2px purple;
    }
    .page-content .comp-tablero .tabla{
        height: 40px !important;
    }
    .pages-sorteo .row-2.row-principal{
        align-items: center;
        justify-content: center;
    }
    .pages-cartones .panel-cartones{
        margin-top: 50px  !important;
    }
    .pages-cartones .row-principal.cartones-row{
        transform: scale(1) !important;
    }

}