.comp-carton{
    background-color: yellow;
    max-width: 160px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.comp-carton .carton{
    background-color: red;
    color:white;
    display: flex;
    flex-direction: column;
    
}
.comp-carton .carton-header{
    display: flex;
    justify-content: space-around;
    background-color: purple;
}
.comp-carton .carton-footer{
    display: flex;
    justify-content: center;
    background-color: purple;
}
.comp-carton .carton-tittle{
    display: flex;
    justify-content: center;
    width: 30px;
    border:solid 1px gray;
    align-items: center;
}
.comp-carton .carton-content{
    display: flex;
    flex-direction: column;
    background-color: white;
    color:black;
    
}
.comp-carton .carton-content .carton-row{
    display: flex;
    justify-content: center;
}
.comp-carton .carton-content .carton-column{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 20px;
    border:solid 1px gray
}
.comp-carton .carton-header .fa-trash:hover{
    color:red;
    cursor: pointer;
}
.comp-carton.hide{
    display: none !important;
}
.comp-carton .carton-column[data-salio=true]{
    background-color: red;
    color:white
}
.comp-carton .carton-column[data-salio=false]{
    background-color: white;
    color:black
}
.carton-row-3 .carton-column-n{
    background-color: green !important;
}
.carton-row.winner .carton-column{
    background-color: green !important;
}



.carton_container
{
    background:url('/public/images/min/fondo_carton_min.png');
    width:130px;
    background-size:100%;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    padding:10px !important;
    gap:5;
    position: relative;
    
    
}
.carton_container_especial
{
    background:url('/public/images/especial/fondo-dorado-bingo-min.png');
    width:130px;
    background-size:100%;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    padding:10px !important;
    gap:5;
    position: relative;
    
    
}
.carton_container:hover{
    /*transform: scale(1.5) !important;*/
    border: solid 2px red;
    z-index: 100 !important;
}
.carton_container.marcaAgua::after{
    content: '';
    width: 130px;
    height: 210px;
    position: absolute;
    background: url("/public/images/MarcaAguaBingo.png") !important;
    background-size: 90% 100%  !important;
    background-repeat:no-repeat  !important;
    background-position: center top  !important;
    z-index: 1  !important;
  }
.carton_container_winner
{
    background:url('/public/images/fondo_carton.png');
    width:130px;
    background-size:100%;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    padding:10px;
    gap:5;   
    border:dashed 4px greenyellow;
    -webkit-animation-name: rubberBand;
    animation-name: rubberBand;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation : rubberBand 1.5s 5 ;
    
}
.carton_container_winner:after
{
    content: "CARTON GANADOR";
    width: 130px;
    height: 220px;
    background: #00000000;
    position: absolute;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color:white;
    text-shadow: 2px 2px green;
    font-size: 12px !important;    
}
  

.carton_head
{
    width:120px;
    height:20px;
    background:green;
    display:flex;
    align-items:center;
    justify-content:space-evenly;
    font-weight:600;
    font-size:12px;
}

.carton_sorteo
{
    width:120px;
    height:20px;
    background:#fcefd1;
    color:black;
    display:flex;
    align-items:center;
    justify-content:center;
    font-weight:600;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    text-align:center;
    margin-top: 2px;
    margin-bottom: 2px;
    font-size:12px;
}
.carton_sorteo_span
{
    width:160px;
    text-overflow:ellipsis;
    white-space:nowrap; 
    overflow:hidden;
}

.carton_content_grid
{
    width:auto;
    height:auto;
    color:black;
    display:grid;
    align-items:center;
    justify-content:center;
    font-weight:600;
    grid-template-columns:repeat(5,22px);
    grid-template-rows:repeat(5,22px);
    grid-auto-flow: column;
    font-size:12px;
    
    padding: 5px;
    
}

.carton_number_free
{
    background:#26830a;
    height:20px;
    width:20px;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:7px;
    color:white;
    font-weight:bolder
}

.carton_number_normal
{
    background:#fcefd1;
    height:20px;
    width:20px;
    display:flex;
    justify-content:center;
    align-items:center;
}
.carton_number_active
{
    background:#c21111;
    height:20px;
    width:20px;
    display:flex;
    justify-content:center;
    align-items:center;
    color:#fff
}
.carton_number_active.last_ball
{
    background:#ff0000 ;
    color:#fff;
    font-weight: bold;
    border:dashed 2px rgb(119, 255, 0);
    z-index: 10;
}
.carton_number_winner
{
    background:greenyellow;
    height:20px;
    width:20px;
    display:flex;
    justify-content:center;
    align-items:center;
    color:#000
}

.carton_footer
{
    width:130px;
    height:30px;
    background:black;
    color:white;
    display:flex;
    align-items:center;
    justify-content:center;
    font-weight:600;
    font-size:12px;
}
.carton_individual
{
   margin-top: 10px;
}


.carton_individual.list-mode{
    max-height: 120px;
    margin-bottom: 10px;
    
}
.carton_individual.list-mode div{
    max-height: 110px;
}
.carton_individual.list-mode div::after{
    display: none;
}


.carton_ribbon {
    position: absolute;
    z-index: 1;
    overflow: hidden;
    width: 130px;
    height: 230px;
    text-align: right;
    background-color: #00000000 !important;
    margin-top: -10px;
}

.carton_ribbon span {
    font-size: 10px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background: #04B00F;
    background: linear-gradient(#04B00F 0%, #828205 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 10px; right: -25px;
}

.carton_ribbon span.green_mode{
    background: #79A70A !important;
    background: linear-gradient(#04B00F 0%, #828205 100%) !important;
}
.carton_ribbon span.red_mode{
    background: #F70505 !important;
    background: linear-gradient(#F70505 0%, #8F0808 100%) !important;
}
.carton_ribbon span.orange_mode{
    background: #F79E05 !important;
  background: linear-gradient(#F79E05 0%, #8F5408 100%) !important;
}



.carton_ribbon span::before {
    
    content: "";
    position: absolute; left: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid #79A70A;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #79A70A;
    display: none;
}
.carton_ribbon span::after {
    content: "";
    position: absolute; right: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #79A70A;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #79A70A;
    display: none;
}












@-webkit-keyframes rubberBand {
    0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    }
    30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
    }
    40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
    }
    50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
    }
    65% {
    -webkit-transform: scale3d(.95, 1.05, 1);
    transform: scale3d(.95, 1.05, 1);
    }
    75% {
    -webkit-transform: scale3d(1.05, .95, 1);
    transform: scale3d(1.05, .95, 1);
    }
    100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    }
}
@keyframes rubberBand {
    0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    }
    30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
    }
    40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
    }
    50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
    }
    65% {
    -webkit-transform: scale3d(.95, 1.05, 1);
    transform: scale3d(.95, 1.05, 1);
    }
    75% {
    -webkit-transform: scale3d(1.05, .95, 1);
    transform: scale3d(1.05, .95, 1);
    }
    100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    }
} 