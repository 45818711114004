
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34 !important;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader-new{
  width: 100vw;
  height: 100vh;
  background: #282c34;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
  position: fixed;
  top:0;
  left: 0;
  right: 0;
}

.loader-new img{
  width: 150px;
  height: auto;
  animation: myAnim 1s ease 0s 20 normal forwards

}
@keyframes myAnim {
	0% {
		transform: scale(0.5);
	}

	100% {
		transform: scale(1);
	}
}

