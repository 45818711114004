.page-login{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}
.form-login{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: purple;
    box-shadow: 0px 2px 10px 2px #00000033;
 
}
.form-login-left{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    padding: 50px;
}
.form-login-right{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px;
}