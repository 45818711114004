.comp-searchBar svg[data-prefix~="fas"]{
    color:gray;
    margin-left: -25px;
    position: relative;
    cursor: pointer;
}
.comp-searchBar .Search-Bar{
    height: 20px;
    padding-right: 30px;
    padding-left: 10px;
    border-radius: 5px;
    border: none;
}
.comp-searchBar{
    display:flex;
    align-items: center;
    justify-content: flex-start;
}