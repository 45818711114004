.pages-promotor .page-content{
   
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.pages-promotor .promotor-section{
    min-height: 300px;
    background-color: transparent;
    margin-top: 0px;
    margin-bottom: 10px;
}
.pages-promotor .promotor-section-1{
    background-color: #000000;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
}
.pages-promotor .promotor-section-2{
    display: flex;
    justify-content: center !important;
    column-gap: 20px;
}
.promotor-section-2{
    padding: 20px;
    display: grid !important;
    grid-template-columns: 500px 500px;
}
.promotor-section-2 .lista-promotor{
 display: flex;
 flex-direction: column;
 align-items: center;
 background-color: #00000011;
 padding-left: 20px;
 padding-right: 20px;
}
.promotor-section-2 h5{
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.promotor-section-2 button{
    margin-top: 10px;
    background-color: purple;
    color: white;
    height: 50px;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    padding: 10px;
    border:none;
}
.promotor-section-0{
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    background-color: purple;
    color: white;
    padding: 10px;
    margin-top: -10px;
}
.contacto {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.step-icon{
    height: 30px;
    width: 30px;
    display: flex;
    color:#000;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    font-weight: bold;

}
.step-promotor{
    display: flex;
    gap:10px;
    background: gray;
    width: 300px;
    margin: 10px;
    padding: 0px;
    border-radius: 10px;
    height: 50px;
    justify-content: center;
    align-items: center;
    color:#fff;
}
.promotor-steps-all{
    display: flex;
    width: 100%;
    height: 70px;
    justify-content: space-evenly;
}

.promotor-steps-content{
    width: 95%;
    min-height: 500px;
    background: #2a2a2a;
    border-radius: 20px;
    margin: 20px;
}

.paises-section{
    color:#fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.paises-content{
    display: grid;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    grid-template-columns: repeat(3,250px);
}

.paises-item{
    display: flex;
    flex-direction: column;
    margin: 20px;
    border: solid 2px #888;
    padding: 20px;
    padding-bottom: 0px;
    align-items: center;
    justify-content: space-between;
}
.paises-item span{
    font-size: large;
    font-weight: bold;
    height: 30px;
    margin-top: 20px;
}

.paises-item:hover {
    border: solid 2px white;
    cursor: pointer;
}



.zonas-section{
    color:#fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.zonas-content{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    flex-wrap: wrap;
}

.zonas-item{
    display: flex;
    flex-direction: column;
    margin: 20px;
    border: solid 2px #888;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
}
.zonas-item span{
    font-size: large;
    font-weight: bold;
    height: 30px;
}

.zonas-item:hover {
    border: solid 2px purple;
    cursor: pointer;
}


/*PROMOTORES ZONA*/
.promotores-section{
    color:#fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.promotores-content{
    display: grid;
    justify-content: center;
    align-items: center;
    min-height: 350px;
    grid-template-columns: repeat(3,250px);
}

.promotores-item{
    display: flex;
    flex-direction: column;
    margin: 20px;
    border: solid 2px #888;
    padding: 20px;
    padding-bottom: 0px;
    align-items: center;
    justify-content: space-between;
}
.promotores-item span{
    font-size: large;
    font-weight: bold;
    height: 30px;
    margin-top: 20px;
}

.promotores-item:hover {
    border: solid 2px white;
    cursor: pointer;
}






@media only screen and (max-width:420px){

    .promotor-section-2{
        padding: 0;
        grid-template-columns: 95vw ;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .lista-promotor{
       
        padding: 0px !important;
        border-radius: 10px;
        margin-top: 10px;
        padding-bottom: 10px !important;
        align-items: center !important;
        justify-content: center;
    }
    .lista-promotor .contacto{
        border:solid 2px purple !important;
        margin-top: 10px;
        width: 95% !important;
        padding: 5px;
        border-radius: 10px;
        align-items: center !important;
        justify-content: space-evenly;
    }
    .lista-promotor .contacto h5{
        width: 45% !important;
    }
    .lista-promotor .contacto button{
        width: 50% !important;
    }
    .promotor-section-0{
        flex-direction: column;
        align-items: center;
    }

}