.pages-home .page-content{
    height: 70vh;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.pages-home .home-section{
    height: 400px;
    background-color: transparent;
    margin-top: 10px;
    margin-bottom: 10px;
}
.pages-home .home-section-1 .carrousel{
    display: flex;
    justify-content: space-evenly;
}
.pages-home .home-section-1{
    background-color: black;
    margin-top: 0px;
}
.pages-home .home-section-2{
    display: flex;
    justify-content: center !important;
    column-gap: 20px;
}
.pages-home  .ver-cartones-disponibles span{
    color: white;
    font-weight: bold;
}
.pages-home  .ver-cartones-disponibles{
    height: 50px;
    background-color: green;
    color: white;
    border-radius: 10px;
    cursor: pointer;
}
.pages-home  .home-section-0{
    display: flex;
    justify-content: center !important;
    column-gap: 20px;
}
.sorteo{
    background-color: transparent;
    position: absolute;
    top: 90px;
    left: 40vw;
    height: 60px;
    width: 250px;
    font-size: 50px;
    font-weight: bold;
    color:black;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size:40px;
}
.sorteo span{
    color:red
}

.premios{
    
    position: absolute;
    top: 160px;
    right: 4vw;
    height: 60px;
    width: 300px;
    font-size: 50px;
    font-weight: bold;
    color:black;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    font-size:25px;
    flex-direction: column;
    row-gap:5px
}

.fecha{
    position: absolute;
    bottom: 30px;
    left: 40vw;
    height: 60px;
    width: 350px;
    font-size: 50px;
    font-weight: bold;
    color:white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size:30px;
    flex-direction: column;
    row-gap:0px;
}
.fecha a{
    color: white;
    cursor: pointer;
}

@media only screen and (max-width:420px){
    .sorteo{
        top: 45px;
        left: 24vw;
        height: 60px;
        width: 90vw;
        font-size: 20px;
    }
    .premios{
        right: 13vw;
        font-size: 18px;
        top: 170px;
        row-gap:8px
    }
    .fecha{
        left: 8vw;
        font-size:26px;
        bottom: 40px;
    }
    .home-section-1.home-section{
        height: 500px;
        margin-top: 50px;
    }
    .home-section-2.home-section{
        height: auto;
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
    }
}