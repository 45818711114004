.comp-header{
    background: purple;
    color:white;
    display: flex;
    justify-content: space-between;
    min-height: 50px;
    padding-left: 50px;
    padding-right: 50px;
    z-index: 100;
}
.menu-link-header{
    display: flex;
    min-height: 50px;
    align-items: center;
    color: white;
    column-gap: 20px;
    justify-content: center;
}
.main-menu-link{
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    column-gap: 10px;
    font-weight: 500;
}
.main-menu-link svg[data-prefix~="fas"]{
    color:white;
}
.div-right-header{
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.div-login{
    display: flex;
    align-items: center;
    column-gap: 10px;
}
a{
    text-decoration: none;
}

@media only screen and (max-width:420px){
    .menu-link-header-mobile{
        display: flex;
        column-gap: 10px;
    }
}