#footer{
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    bottom: 0 !important;
    left: 0;
    right: 0;
}
.footer-row.row1{
    display: flex;
    
    align-items: flex-start;
    margin-top: 30px;
    width: 100%;
    position: relative;
    justify-content: center;
    
}
.footer-column{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-width: 22vw;
    max-width: 22vw;
    padding-bottom: 20px;
}
.footer-row.row2{
    display: flex;
    margin-bottom: 10px;
    justify-content: center;
    width: 100%;
}
.comp-site-logo .App-logo{
    display: none;
}
.footer-inner-row{
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;;
}
.footer-column .social-media{
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}


@media only screen and (max-width:420px){
   
    .footer-row.row1{
        
        flex-direction: column;
        align-items: center;
        
    }
    .footer-column{
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 90vw;
    }
    .footer-row.row2{
        display: flex;
        margin-bottom: 20px;
        justify-content: center;
        width: 90vw;
        font-size: 12px;
        align-items: center;
        text-align: center;
    }
    .comp-site-logo .App-logo{
        display: none;
    }
    .footer-inner-row{
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;;
    }
    .footer-column .social-media{
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        column-gap: 20px;
    }
    .footer-column.column-4 .footer-inner-row{
        flex-direction: row;
        column-gap: 20px;
    }
}