.comp-tablero{
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.comp-tablero .tabla{
    color:white;
    display: grid;
    background-color: transparent;
    grid-template-columns:  40px 40px 40px 40px 40px 40px 40px;
}
.comp-tablero .tabla-header{
    display: flex;
    justify-content: center;
    background-color: purple;
    align-items: flex-start;
}
.comp-tablero .tabla-tittle{
    display: flex;
    justify-content: center;
    width: 40px;
    border:solid 1px gray;
    align-items: center;
}
.comp-tablero .tabla-content{
    display: flex;
    flex-direction: column;
    background-color: white;
    color:black;
}
.comp-tablero .tabla-content .tabla-row{
    display: flex;
    justify-content: center;
}
.comp-tablero .tabla-content .tabla-column{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 20px;
    border:solid 1px gray
}
.comp-tablero .tabla-column[data-salio=true]{
    background-color: red;
    color:white
}
.comp-tablero .tabla-column[data-salio=false]{
    background-color: white;
    color:black
}
.hide{
    display: none !important;
}
.bolita{
    width: 35px;
    height: 35px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color:black;
    font-weight: bold;
    margin-bottom: 5px;
    background: url('/public/images/bola_gris.png') !important;
    background-size: 100% !important;
    background-position: center  !important;
    background-repeat: no-repeat;
    box-shadow:0px 0px 5px 0.2px black;
}

.bolita[data-number='0']{
    opacity: 0 !important;
}
.bolita.ultima{
    background: url('/public/images/bola_rojo.png') !important;
    background-size: 100% !important;
    background-position: center  !important;
    background-repeat: no-repeat;
    /*background-color: #f57677 !important;*/
}